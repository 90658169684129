import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const stickyClasses = 'sticky top-0';
const notStickyClasses = 'relative';

const NavBar = ({ className, children, isSticky }) => {
  const url = new URL(window.location.href);

  const searchParams = new URLSearchParams(url.search);

  return (
    <>
      <div
        className={classnames(
          `bg-dark z-20  border-black  ${searchParams.has('row') && 'hidden123'}`,
          // `bg-${localStorage.getItem('theme') === 'true' ? 'white text-color-black' : 'secondary-dark'} z-20 border-black px-1`,
          isSticky && stickyClasses,
          !isSticky && notStickyClasses,
          className
        )}
      >
        {children}
      </div>
    </>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  isSticky: PropTypes.bool,
};

export default NavBar;
