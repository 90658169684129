import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useDrag } from 'react-dnd';
import Icon from '../Icon';
import { StringNumber } from '../../types';
import DisplaySetMessageListTooltip from '../DisplaySetMessageListTooltip';

/**
 * Display a thumbnail for a display set.
 */
const Thumbnail = ({
  displaySetInstanceUID,
  className,
  imageSrc,
  imageAltText,
  description,
  seriesNumber,
  numInstances,
  countIcon,
  messages,
  dragData,
  isActive,
  onClick,
  onDoubleClick,
  onClickUntrack,
  isTracked,
}): React.ReactNode => {
  // TODO: We should wrap our thumbnail to create a "DraggableThumbnail", as
  // this will still allow for "drag", even if there is no drop target for the
  // specified item.
  const [collectedProps, drag, dragPreview] = useDrag({
    type: 'displayset',
    item: { ...dragData },
    canDrag: function (monitor) {
      return Object.keys(dragData).length !== 0;
    },
  });

  const [lastTap, setLastTap] = useState(0);
  const url = new URL(window.location.href);

  const searchParams = new URLSearchParams(url.search);

  const handleTouchEnd = e => {
    const currentTime = new Date().getTime();
    const tapLength = currentTime - lastTap;
    if (tapLength < 300 && tapLength > 0) {
      onDoubleClick(e);
    } else {
      onClick(e);
    }
    setLastTap(currentTime);
  };
  const handleApproveClick = e => {
    e.stopPropagation();
    console.log('Approve clicked');
  };

  return (
    <div
      className={classnames(
        className,
        'group mb-8 flex flex-1 cursor-pointer select-none flex-col px-3 outline-none'
      )}
      id={`thumbnail-${displaySetInstanceUID}`}
      data-cy={`study-browser-thumbnail`}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      onTouchEnd={handleTouchEnd}
      onClickUntrack={onClickUntrack}
      role="button"
      tabIndex="0"
    >
      <div ref={drag}>
        <div
          className={classnames(
            'relativesss  h-38 flex-1 items-center justify-center overflow-hidden rounded-md bg-black text-base text-white',
            isActive
              ? 'border-primary-light border-2'
              : 'border-common-lightblue border hover:border-blue-300'
          )}
        >
          {imageSrc ? (
            <>
              <img
                src={imageSrc}
                alt={imageAltText}
                // className="h-full w-full object-contain"
                crossOrigin="anonymous"
              />
              {isTracked && (
                <div className="flex">
                  {isActive ? (
                    <div className="justify-between11 flex flex-1 items-center  bg-opacity-75">
                      <div className="ml-4 flex items-center">
                        <Icon
                          name="delete-study-icon"
                          className="mr-2 w-5 text-white"
                          onClick={onClickUntrack}
                        />
                      </div>
                      <div className="flex flex-1 flex-row items-center  bg-opacity-75"></div>
                      <Icon
                        name="approve-study-icon"
                        className="mr-2 w-5 text-white"
                        onClick={handleApproveClick}
                      />
                    </div>
                  ) : (
                    <>
                      <div className="flex flex-1 flex-row items-center  bg-opacity-75"></div>
                      <Icon
                        name="exclaimation-mark"
                        className="mr-2 w-5 text-white"
                      />
                    </>
                  )}
                </div>
              )}
            </>
          ) : (
            <>
              <div>{imageAltText}</div>
              {/* <div>
                <Icon
                  name="delete-study-icon"
                  className="text-primary-main absolute top-2 left-2 h-4 w-4"
                />
                <Icon
                  name="approve-study-icon"
                  className="text-primary-main absolute top-2 right-2 h-4 w-4"
                />
              </div> */}
            </>
          )}
        </div>
        <div
          className={`flex flex-1 flex-row items-center pt-2 text-base text-blue-300 ${searchParams.has('row') && 'hidden'}`}
        ></div>
        <div
          className={`flex flex-1 flex-row items-center pt-2 text-base text-blue-300 ${searchParams.has('row') && 'hidden'}`}
        >
          <div className="flex flex-1 items-center justify-between">
            <div className="mr-4 flex items-center">
              <span className="text-primary-main font-bold">{'S: '}</span>
              {seriesNumber}
            </div>
            <div className="flex flex-1 flex-row items-center"></div>
            <Icon
              name={countIcon || 'group-layers-new'}
              className=" w-7"
            />
            {` ${numInstances}`}
          </div>

          <DisplaySetMessageListTooltip
            messages={messages}
            id={`display-set-tooltip-${displaySetInstanceUID}`}
          />
        </div>
        <div className="break-all text-base text-white">{description}</div>
      </div>
    </div>
  );
};

Thumbnail.propTypes = {
  displaySetInstanceUID: PropTypes.string.isRequired,
  className: PropTypes.string,
  imageSrc: PropTypes.string,
  /**
   * Data the thumbnail should expose to a receiving drop target. Use a matching
   * `dragData.type` to identify which targets can receive this draggable item.
   * If this is not set, drag-n-drop will be disabled for this thumbnail.
   *
   * Ref: https://react-dnd.github.io/react-dnd/docs/api/use-drag#specification-object-members
   */
  dragData: PropTypes.shape({
    /** Must match the "type" a dropTarget expects */
    type: PropTypes.string.isRequired,
  }),
  imageAltText: PropTypes.string,
  description: PropTypes.string.isRequired,
  seriesNumber: StringNumber.isRequired,
  numInstances: PropTypes.number.isRequired,
  messages: PropTypes.object,
  isTracked: PropTypes.bool,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
  onClickUntrack: PropTypes.func.isRequired,
};

Thumbnail.defaultProps = {
  dragData: {},
};

export default Thumbnail;
