import React, { ReactNode, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import NavBar from '../NavBar';
import Svg from '../Svg';
import Icon from '../Icon';
import IconButton from '../IconButton';
import Dropdown from '../Dropdown';
import HeaderPatientInfo from '../HeaderPatientInfo';
import { PatientInfoVisibility } from '../../types/PatientInfoVisibility';
import classnames from 'classnames';

function Header({
  children,
  menuOptions,
  isReturnEnabled,
  onClickReturnButton,
  isSticky,
  WhiteLabeling,
  showPatientInfo = PatientInfoVisibility.VISIBLE_COLLAPSED,
  servicesManager,
  Secondary,
  appConfig,
  ...props
}: withAppTypes): ReactNode {
  const { t } = useTranslation('Header');
  const [isLightTheme, setIsLightTheme] = useState(false);

  // TODO: this should be passed in as a prop instead and the react-router-dom
  // dependency should be dropped
  const onClickReturn = () => {
    if (isReturnEnabled && onClickReturnButton) {
      onClickReturnButton();
    }
  };
  const onClickTab = themeName => {
    setIsLightTheme(themeName);
    localStorage.setItem('theme', themeName);
  };

  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const StudyInstanceUIDs = params.get('StudyInstanceUIDs');
  const seriesInstanceUIDs = localStorage.getItem('SeriesInstanceUID'); //params.get('StudyInstanceUIDs');
  // const handleOpenAretemViewer = () => {
  //   console.log('seriesInstanceUIDsseriesInstanceUIDs', seriesInstanceUIDs, StudyInstanceUIDs);
  //   // const aviewer = `dicomviewer://viewer?seriesId=${seriesInstanceUIDs}&studyId=${StudyInstanceUIDs}`;
  //   // window.location.href =
  //   return window.open(
  //     `dicomviewer://viewer?seriesId=${seriesInstanceUIDs}&studyId=${StudyInstanceUIDs}`,
  //     '_blank'
  //   );
  //   //
  //   // window.open(aviewer, '__blank');
  //   // window.location.href = aviewer;
  // };

  return (
    <NavBar
      isSticky={isSticky}
      {...props}
    >
      <div className="relative h-[68px] items-center ">
        <div className="absolute left-0 top-1/2 flex -translate-y-1/2 items-center">
          <div
            className={classNames(
              'mr-3 inline-flex items-center',
              isReturnEnabled && 'cursor-pointer'
            )}
            onClick={onClickReturn}
            data-cy="return-to-work-list"
          >
            {isReturnEnabled && (
              <Icon
                name="chevron-left"
                className="text-primary-active w-8"
              />
            )}
            {/* <div className="ml-1">
              {WhiteLabeling?.createLogoComponentFn?.(React, props) || (
                <Svg name="artem-logo-white" />
              )}
            </div> */}
          </div>
        </div>
        <div className="absolute top-1/2 left-[250px]  h-8 -translate-y-1/2">{Secondary}</div>
        {children && (
          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform rounded-lg bg-white bg-opacity-30">
            <div className="flex items-center justify-center space-x-2">{children}</div>
          </div>
        )}
        {/*   <div className="absolute right-0 top-1/2 flex -translate-y-1/2 select-none items-center">
          {showPatientInfo !== PatientInfoVisibility.DISABLED && (
            <HeaderPatientInfo
              servicesManager={servicesManager}
              appConfig={appConfig}
            />
          )}
          <div className="border-primary-dark mx-1.5 h-[25px] border-r"></div>
         <div className="flex-shrink-0">
            <Dropdown
              id="options"
              showDropdownIcon={false}
              list={menuOptions}
              alignment="right"
            >
              <IconButton
                id={'options-settings-icon'}
                variant="text"
                color="inherit"
                size="initial"
                className="text-primary-active hover:bg-primary-dark h-full w-full"
              >
                <Icon name="icon-settings" />
              </IconButton>
            </Dropdown>
          </div>
        </div>*/}
        {params !== 0 && (
          <div className="absolute right-0 top-1/2 flex -translate-y-1/2 cursor-pointer select-none items-center gap-3 rounded-lg bg-white bg-opacity-30 p-1.5 ">
            <div>
              <Icon
                name="3D-Icon"
                className="text-common-bright !text-common-bright hover:!bg-primary-dark hover:!text-primary-light inline-flex h-4 w-4 items-center justify-center rounded-md border-white text-center text-lg font-bold outline-none transition duration-300 ease-in-out hover:opacity-80 focus:opacity-80 focus:outline-none active:opacity-100"
                onClick={() => {
                  const aviewer = `dicomviewer://viewer?seriesId=${seriesInstanceUIDs}&studyId=${StudyInstanceUIDs}`;
                  window.open(aviewer, '_blank');
                }}
              />
            </div>
            <div className="border-white-active border-l py-3"></div>
            <div>
              <Icon
                name="eye-icon-inobitec"
                className="text-common-bright !text-common-bright hover:!bg-primary-dark hover:!text-primary-light inline-flex h-5 w-4 cursor-pointer items-center justify-center rounded-md text-center text-lg font-bold outline-none transition duration-300 ease-in-out hover:opacity-80 focus:opacity-80 focus:outline-none active:opacity-100"
                onClick={() => {
                  const inobeticUrl = `inobitec://?pacs-aetitle=DCM4CHEE&patient-id=${JSON.parse(localStorage.getItem('single_study'))[0]['00100020'].Value[0]}&series-uid=${seriesInstanceUIDs}&open-series-images`;
                  window.open(inobeticUrl, '_blank');
                }}
              />
            </div>
            {/* <div className="border-white-active border-l py-3"></div> */}
            {/* <div onClick={() => onClickTab(!isLightTheme)}>
              <Icon
                name="moon-icon"
                className="text-common-bright !text-common-bright hover:!bg-primary-dark hover:!text-primary-light ml-1 inline-flex h-5 w-4 items-center justify-center rounded-md border-white text-center text-lg font-bold outline-none transition duration-300 ease-in-out hover:opacity-80 focus:opacity-80 focus:outline-none active:opacity-100"
              />
            </div> */}
          </div>
        )}

        {/* ) */}
        {/* : ( */}
        {/* <div onClick={() => onClickTab(!isLightTheme)}>
            <Icon
              name="dark-theme-icon"
              className="text-common-bright !text-common-bright hover:!bg-primary-dark hover:!text-primary-light inline-flex w-10 items-center justify-center rounded-md rounded border-white text-center text-lg font-bold outline-none transition duration-300 ease-in-out hover:opacity-80 focus:opacity-80 focus:outline-none active:opacity-100 "
            />
          </div> */}
        {/* // ) */}
        {/* // } */}
      </div>
    </NavBar>
  );
}

Header.propTypes = {
  menuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.string,
      onClick: PropTypes.func.isRequired,
    })
  ),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  isReturnEnabled: PropTypes.bool,
  isSticky: PropTypes.bool,
  onClickReturnButton: PropTypes.func,
  WhiteLabeling: PropTypes.object,
  showPatientInfo: PropTypes.string,
  servicesManager: PropTypes.object,
};

Header.defaultProps = {
  isReturnEnabled: true,
  isSticky: false,
};

export default Header;
